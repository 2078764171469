<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Factures Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-5">
              <v-toolbar-title>
                <v-dialog
                  v-model="client"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <template v-slot:default="dialog">
                    <v-card :loading="myloading" :disabled="myloading">
                      <v-card-text style="height: 200px;">
                        <v-container grid-list-md>
                          <v-form ref="formClient">
                            <v-layout wrap>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="nom"
                                  :label="$t('client.names')"
                                  clearable
                                  prepend-inner-icon="mdi-account"
                                  required
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="telephone"
                                  :label="$t('client.contact')"
                                  type="number"
                                  required
                                  prepend-inner-icon="mdi-phone"
                                  clearable
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          class="ma-2"
                          tile
                          color="red accent-4"
                          @click="dialog.value = false"
                          dark
                        >
                          <v-icon left>mdi-close-circle-outline</v-icon
                          >{{ $t("close") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="ma-2"
                          tile
                          outlined
                          color="blue accent-4"
                          @click="saveclient()"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="1000px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-file-document</v-icon
                      >{{ $t("facture.new") }}
                    </v-btn>
                  </template>
                  <v-card :loading="myloading" :disabled="myloading">
                    <v-card-title class="headline blue accent-4 justify-center">
                      <span class="headline white--text"
                        >Creer une facture</span
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-form ref="formFacture">
                          <v-layout wrap>
                            <v-flex xs8 sm10 md10>
                              <v-autocomplete
                                v-model="modelSelectedClient"
                                :items="matchedClient"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('client.name1')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex>
                              <div class="mt-4">
                                <v-btn
                                  @click="newclient()"
                                  class="mx-2"
                                  fab
                                  dark
                                  color="indigo"
                                >
                                  <v-icon dark>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedTypeFacture"
                                :items="matchedTypeFacture"
                                item-text="libelle"
                                item-value="idTypeFacture"
                                :label="$t('facture.bill')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="matchedDepot"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('livraison.store')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedArticles"
                                :items="matchedArticles"
                                item-text="article.designation"
                                item-value="article.idArticle"
                                :label="$t('facture.item')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="quantite"
                                :label="$t('facture.quantity')"
                                :rules="[(v) => !!v || $t('facture.quantity1')]"
                              >
                                <v-icon
                                  v-if="max != null"
                                  slot="append"
                                  color="green"
                                >
                                  {{ this.getOneEntree.quantite }}
                                </v-icon>
                                <v-icon v-else slot="append" color="green">
                                  {{ 0 }}
                                </v-icon>
                              </v-text-field>
                            </v-flex>

                            <template>
                              <v-row class="mb-2" justify="center">
                                <div>
                                  <v-switch
                                    v-model="switch1"
                                    class="ms-4"
                                    :label="$t('Facture.add')"
                                  >
                                  </v-switch>
                                </div>
                                <v-spacer></v-spacer>
                                <div>
                                  <v-btn
                                    class="ma-2"
                                    tile
                                    color="blue accent-4"
                                    @click="ajout"
                                    dark
                                  >
                                    <v-icon left>mdi-cart-arrow-down</v-icon
                                    >{{ $t("facture.cart") }}
                                  </v-btn>
                                </div>
                              </v-row>
                            </template>
                            <v-flex xs12 sm12 md12>
                              <v-row>
                                <v-col cols="4"> </v-col>
                              </v-row>

                              <template>
                                <v-expand-transition>
                                  <v-simple-table
                                    fixed-header
                                    height="200px"
                                    color="grey darken-1"
                                    dark
                                  >
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t("facture.item1") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.price") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.quantity") }}
                                        </th>
                                        <th>{{ $t("facture.total") }}</th>
                                        <th>{{ $t("delete") }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in ajoutArticle"
                                        :key="item.designation"
                                      >
                                        <td>{{ item.designation }}</td>
                                        <td>{{ item.prix }}</td>
                                        <td>{{ item.quantite }}</td>
                                        <td>{{ item.tot }}</td>
                                        <td>
                                          <v-icon
                                            small
                                            @click="supprimerLigne(item)"
                                            >mdi-delete</v-icon
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-expand-transition>
                              </template>
                            </v-flex>
                            <template>
                              <v-row justify="center" no-gutters>
                                <v-col cols="12" md="6" offset-md="6">
                                  <v-text-field
                                    v-model="newRemise"
                                    :label="$t('facture.add')"
                                    v-mask="mask"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" offset-md="6">
                                  <span>
                                    {{ $t("facture.total1") }}:
                                    <h3
                                      class="red--text"
                                      id="total"
                                      name="total"
                                    >
                                      {{ totalArt }} FCFA
                                    </h3>
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                        :disabled="isValidFacture"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        CREER UNE FACTURE
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogUpdate" persistent max-width="1000px">
                  <v-card :loading="myloading" :disabled="myloading">
                    <v-card-title class="headline blue accent-4 justify-center">
                      <span class="headline white--text">Modifier facture</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container grid-list-md>
                        <v-form ref="formFacture">
                          <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedClient"
                                :items="matchedClient"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('client.name1')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedTypeFacture"
                                :items="matchedTypeFacture"
                                item-text="libelle"
                                item-value="idTypeFacture"
                                :label="$t('facture.bill')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="matchedDepot"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('livraison.store')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedArticles"
                                :items="matchedArticles"
                                item-text="article.designation"
                                item-value="article.idArticle"
                                :label="$t('facture.item')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="quantite"
                                :label="$t('facture.quantity')"
                                :rules="[(v) => !!v || $t('facture.quantity1')]"
                              >
                                <v-icon
                                  v-if="max != null"
                                  slot="append"
                                  color="green"
                                >
                                  {{ this.getOneEntree.quantite }}
                                </v-icon>
                                <v-icon v-else slot="append" color="green">
                                  {{ 0 }}
                                </v-icon>
                              </v-text-field>
                            </v-flex>

                            <template>
                              <v-row class="mb-2" justify="center">
                                <v-btn
                                  class="ma-2"
                                  tile
                                  color="blue accent-4"
                                  @click="ajoutUp"
                                  dark
                                >
                                  <v-icon left>mdi-cart-arrow-down</v-icon
                                  >{{ $t("facture.cart") }}
                                </v-btn>
                              </v-row>
                            </template>
                            <v-flex xs12 sm12 md12>
                              <v-row>
                                <v-col cols="4"> </v-col>
                              </v-row>

                              <template>
                                <v-expand-transition>
                                  <v-simple-table
                                    fixed-header
                                    height="200px"
                                    color="grey darken-1"
                                    dark
                                  >
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t("facture.item1") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.price") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.quantity") }}
                                        </th>
                                        <th>{{ $t("facture.total") }}</th>
                                        <th>{{ $t("delete") }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in ajoutArticleUp"
                                        :key="item.designation"
                                      >
                                        <td>{{ item.designation }}</td>
                                        <td>{{ item.prix }}</td>
                                        <td>{{ item.quantite }}</td>
                                        <td>{{ item.tot }}</td>
                                        <td>
                                          <v-icon
                                            small
                                            @click="supprimerLigneUp(item)"
                                            >mdi-delete</v-icon
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-expand-transition>
                              </template>
                            </v-flex>
                            <template>
                              <v-row justify="center" no-gutters>
                                <v-col cols="12" md="6" offset-md="6">
                                  <v-text-field
                                    v-model="newRemise"
                                    :label="$t('facture.add')"
                                    v-mask="mask"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" offset-md="6">
                                  <span>
                                    {{ $t("facture.total1") }}:
                                    <h3
                                      class="red--text"
                                      id="total"
                                      name="total"
                                    >
                                      {{ totalArt }} FCFA
                                    </h3>
                                  </span>
                                </v-col>
                              </v-row>
                            </template>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeUpdateDialogView"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="update"
                        :disabled="isValidFactureUp"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        MODIFIER UNE FACTURE
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début dialog de payement d'une facture -->
                <v-dialog
                  v-model="dialogView"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <v-card :loading="myloading" :disabled="myloading">
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("bill.payBill")
                      }}</span>
                    </v-card-title>

                    <v-card-text style="height: 400px">
                      <v-container grid-list-md>
                        <v-form ref="formPayer">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.num") }}:
                                <h3 class="black--text">
                                  {{ showItem.facture.numeroFacture }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span
                                v-if="showItem.facture.commande.client"
                                class="ml-2"
                              >
                                {{ $t("facture.customer3") }}:
                                <h2 class="black--text ml-2">
                                  {{ showItem.facture.commande.client.nom }}
                                </h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.type") }}:
                                <h3 class="red--text">
                                  {{ showItem.facture.typeFacture.libelle }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.amount") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{ showItem.facture.reste }}
                                  FCFA
                                </h2>
                              </span>
                            </v-col>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="montantPaye"
                                :rules="[(v) => !!v || $t('bill.received')]"
                                type="number"
                                :label="$t('bill.paid')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-col class="col-6">
                              <v-switch
                                v-show="!showItem.facture.livre"
                                v-model="switchLivre"
                                class="ms-4"
                                :label="$t('Facture.livre')"
                              >
                              </v-switch>
                            </v-col>
                            <v-flex v-show="switchLivre" xs6 sm10 md10>
                              <v-autocomplete
                                v-model="modelSelectedChaufeur"
                                :items="matchedChauffeurs"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('reception.name')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex v-show="switchLivre">
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="indigo"
                                @click="newchauff = true"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeDialogView"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="savePaiement"
                        :disabled="isValidPayement"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("bill.save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->
                <v-dialog
                  v-model="newchauff"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <template v-slot:default="dialog">
                    <v-card :loading="myloading" :disabled="myloading">
                      <v-card-text style="height: 200px;">
                        <v-container grid-list-md>
                          <v-form ref="formChauffeur">
                            <v-layout wrap>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="nomChaufeur"
                                  :label="$t('reception.name')"
                                  clearable
                                  prepend-inner-icon="mdi-account"
                                  required
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="contactChauffeur"
                                  :label="$t('reception.contact')"
                                  type="number"
                                  required
                                  prepend-inner-icon="mdi-phone"
                                  clearable
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          class="ma-2"
                          tile
                          color="red accent-4"
                          @click="dialog.value = false"
                          dark
                        >
                          <v-icon left>mdi-close-circle-outline</v-icon
                          >{{ $t("close") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="ma-2"
                          tile
                          outlined
                          color="blue accent-4"
                          @click="savechauffeur()"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card :loading="myloading" :disabled="myloading">
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="delete_dialog = false"
                        text
                        >{{ $t("no") }}</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="historique" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline blue darken-1 white--text">{{
                      $t("facture.historique_p")
                    }}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Id
                              </th>
                              <th class="text-left">
                                {{ $t("bill.received") }}
                              </th>
                              <th class="text-left">
                                {{ $t("bill.date") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in paiements" :key="item.name">
                              <td>{{ item.idPaiement }}</td>
                              <td>{{ item.montantPaye }}</td>
                              <td>{{ convertDate(item.createdAt) }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="historique = false"
                        text
                        >{{ $t("close") }}</v-btn
                      >
                      <!-- <v-btn
                        color="blue darken-1"
                        @click.once="closeHistorique()"
                        text
                        >{{ $t("yes") }}</v-btn
                      > -->
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="isDirector"
                v-model="searchFacDir"
                append-icon="mdi-magnify"
                @input="axiosSearchDir"
                :label="$t('facture.search')"
                single-line
                hide-details
              ></v-text-field>
              <v-text-field
                v-else
                v-model="searchFac"
                append-icon="mdi-magnify"
                @input="axiosSearch"
                :label="$t('facture.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <template v-if="isDirector">
        <v-data-table
          :headers="headersAdmin"
          :items="itemListDir"
          :loading="loading"
          :search="searchFacDir"
          disable-filtering
          disable-pagination
          disable-sort
          class="elevation-1"
          mobile-breakpoint="800"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{
                $t("datatable-title.facture.listefacture")
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.facture.numeroFacture }}</td>
              <td>
                {{ item.facture.ligneBoutique.boutiquesPK.user.username }}
              </td>

              <td v-if="item.facture.commande.client">
                {{ item.facture.commande.client.nom }}
              </td>
              <td v-else></td>
              <td>{{ item.facture.montantFacture + " FCFA" }}</td>
              <td>
                <template v-if="item.facture.solde">
                  <v-chip color="blue" text-color="white">
                    {{ $t("yes") }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip color="red" text-color="white">
                    {{ $t("no") }}
                  </v-chip>
                </template>
              </td>
              <td>{{ item.facture.reste + " FCFA" }}</td>
              <td>{{ convertDate(item.facture.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="!item.facture.solde"
                      small
                      class="mr-2"
                      @click="checkCommande(item)"
                      v-on="on"
                      >mdi-cash-plus</v-icon
                    >
                    <v-icon v-else small class="mr-2 actionpay" v-on="on"
                      >mdi-cash-plus</v-icon
                    >
                  </template>
                  <span v-if="item.facture.solde">{{ $t("bill.paid3") }}</span>
                  <span v-else>{{ $t("bill.paid2") }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="
                    (isVendeurCaisse || isVendeurCaisseDirecteur) &&
                      item.facture.typeFacture.idTypeFacture !== 3
                  "
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="historiqueFacture(item.facture.idFacture)"
                      v-on="on"
                      >mdi-history</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.historique") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2 actionshow"
                      @click="showFacture(item.facture)"
                      v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="
                        item.facture.solde == false ||
                          item.facture.livre == false
                      "
                      class="mr-2 actionedit"
                      small
                      @click="showEditFacture(item)"
                      v-on="on"
                      >mdi-eraser</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.update") }}</span>
                </v-tooltip>
                <v-icon
                  v-if="isDirector"
                  small
                  class="mr-2 actiondelete"
                  @click="deleteItem(item, 0)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-if="searchFacDir"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearchDir"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosListDir"
            circle
          >
          </v-pagination>
        </div>
      </template>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchFac"
          sort-by="libelle_an"
          class="elevation-1"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("facture.bill1") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.facture.numeroFacture }}</td>
              <td>{{ item.facture.commande.client.nom }}</td>
              <td>{{ item.facture.montantFacture + " FCFA" }}</td>
              <td>
                <template v-if="item.facture.solde">
                  <v-chip color="blue" text-color="white">
                    {{ $t("yes") }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip color="red" text-color="white">
                    {{ $t("no") }}
                  </v-chip>
                </template>
              </td>
              <td>{{ item.facture.reste + " FCFA" }}</td>
              <td>{{ convertDate(item.facture.createdAt) }}</td>
              <td>
                <v-tooltip
                  v-if="
                    (isVendeurCaisse || isVendeurCaisseDirecteur) &&
                      item.facture.typeFacture.idTypeFacture !== 3
                  "
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="!item.facture.solde"
                      small
                      class="mr-2"
                      @click="checkCommande(item)"
                      v-on="on"
                      >mdi-cash-plus</v-icon
                    >
                    <v-icon v-else small class="mr-2 actionpay" v-on="on"
                      >mdi-cash-plus</v-icon
                    >
                  </template>
                  <span v-if="item.facture.solde">{{ $t("bill.paid3") }}</span>
                  <span v-else>{{ $t("bill.paid2") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="historiqueFacture(item.facture.idFacture)"
                      v-on="on"
                      >mdi-history</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.historique") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2 actionshow"
                      @click="showFacture(item.facture)"
                      v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="
                        item.facture.solde == false ||
                          item.facture.livre == false
                      "
                      class="mr-2 actionedit"
                      small
                      @click="showEditFacture(item)"
                      v-on="on"
                      >mdi-eraser</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.update") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-if="searchFac"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearch"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosList"
            circle
          >
          </v-pagination>
        </div>
      </template>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
import i18n from "../../i18n";
import axios from "axios";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
  },
  data: () => ({
    mask: "########",
    searchFac: "",
    switch1: true,
    searchFacDir: "",
    prenom: "",
    factures: {},
    current_pag: 1,
    isLoading: false,
    nom: "",
    telephone: "",
    client: false,
    storage: window.localStorage,
    facture: null,
    dialog: false,
    dialogView: false,
    dialogOneFac: false,
    loading: false,
    myloading: false,
    dialogUpdate: false,
    deleteMessage: null,
    delete_dialog: false,
    historique: false,
    modelSelectedArticles: null,
    modelSelectedTypeFacture: null,
    modelSelectedChaufeur: null,
    modelSelectedClient: null,
    modelSelectedDepot: null,
    delete_item: null,
    search: "",
    inputRemise: false,
    disableBtn: false,
    articles: new Array(),
    // depots: new Array(),
    ajoutArticle: new Array(),
    articleUp: new Array(),
    ajoutArticleUp: new Array(),
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    curMax: 0,
    nomClient: "",
    contactClient: "",
    quantite: "",
    quantiteArt: 0,
    oldRemise: 0,
    newRemise: "",
    montantPaye: 0,
    switchLivre: true,
    newchauff: false,
    nomChaufeur: "",
    contactChauffeur: "",
    editedItem: {
      idFacture: 0,
      facture: {
        idFacture: 0,
        numeroFacture: 0,
        commande: {
          client: {
            idClient: 0,
            nom: "",
            telephone: "",
          },
        },
        file: "",
        montantFacture: 0,
        reste: 0,
        remise: 0,
        solde: false,
        typeFacture: {
          idTypeFacture: 0,
          libelle: "",
        },
        createdAt: "",
      },
      articles: [],
    },
    defaultItem: {
      idFacture: 0,
      facture: {
        idFacture: 0,
        numeroFacture: 0,
        commande: {
          client: {
            idClient: 0,
            nom: "",
            telephone: "",
          },
        },
        file: "",
        montantFacture: 0,
        reste: 0,
        remise: 0,
        solde: false,
        typeFacture: {
          idTypeFacture: 0,
          libelle: "",
        },
        createdAt: "",
      },
      articles: [],
    },
    itemShow: {
      idFacture: 0,
      facture: {
        idFacture: 0,
        numeroFacture: 0,
        commande: {
          client: {
            idClient: 0,
            nom: "",
            telephone: "",
          },
        },
        file: "",
        montantFacture: 0,
        reste: 0,
        remise: 0,
        solde: false,
        typeFacture: {
          idTypeFacture: 0,
          libelle: "",
        },
        createdAt: "",
      },
      articles: [],
    },
    showItem: {
      idFacture: 0,
      facture: {
        idFacture: 0,
        numeroFacture: 0,
        commande: {
          client: {
            idClient: 0,
            nom: "",
            telephone: "",
          },
        },
        file: "",
        montantFacture: 0,
        reste: 0,
        remise: 0,
        solde: false,
        typeFacture: {
          idTypeFacture: 0,
          libelle: "",
        },
        createdAt: "",
      },
      articles: [],
    },
    paiements: [],
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          value: "numeroFacture",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.client"),
          value: "commande.client.nom",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.totalfacture"),
          value: "montantFacture",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.cash"),
          value: "encaisse",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.reste"),
          value: "reste",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.date"),
          value: "createdAt",
          sortbale: false,
        },
        { text: "Actions", value: "action", sortable: false },
      ];
    },

    headersAdmin() {
      return [
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          value: "numeroFacture",
          sortable: false,
        },
        {
          text: this.$t("login.username"),
          value: "ligneBoutique.boutiquesPK.user.nom",
          sortable: false,
        },
        {
          text: this.$t("facture.customer3"),
          value: "commande.client.nom",
          sortable: false,
        },
        {
          text: this.$t("facture.total2"),
          value: "montantFacture",
          sortable: false,
        },
        { text: this.$t("facture.cash"), value: "encaisse", sortable: false },
        { text: this.$t("bill.rest"), value: "reste", sortable: false },
        { text: this.$t("facture.date"), value: "createdAt", sortable: false },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
    total_page() {
      if (this.factures && this.factures.last_page) {
        return this.factures.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.factures != null && this.factures.data) {
        return this.factures.data;
      } else {
        return [];
      }
    },
    itemListDir() {
      if (this.factures != null && this.factures.data) {
        return this.factures.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.factures && this.factures.last_page) {
        return this.factures.last_page;
      } else {
        return 0;
      }
    },

    matchedChauffeurs() {
      if (this.allChauffeur != null) {
        return this.allChauffeur;
      } else {
        return [];
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      getAllClients: "client/getAllClients",
      getAllDepots: "depot/getAllDepotsBoutique",
      allArticles: "articles/getAllArticles",
      allFactures: "facture/getAllFactures",
      allTypeFacture: "typefacture/getAllType_factures",
      _role: "login/getUrole",
      getOneEntree: "article/getOneEntree",
      getOneFactur: "facture/getOneFacture",
      allChauffeur: "chauffeur/getAllChauffeur",
    }),

    matchedArticles() {
      return this.allArticles.map((article) => {
        const articles = article.article;

        return Object.assign({}, article, { articles });
      });
    },

    matchedTypeFacture() {
      //if (!this.modelSelectedArticles) return [];
      return this.allTypeFacture;
    },

    matchedClient() {
      return this.getAllClients.map((client) => {
        const clients = client.nom;
        return Object.assign({}, client, { clients });
      });
    },

    matchedDepot() {
      // console.log(this.getAllDepots);
      return this.getAllDepots.map((depot) => {
        const depotsM = depot.libelle;
        return Object.assign({}, depot, { depotsM });
      });
    },
    // eslint-disable-next-line
    // selectedArticles() {
    //   if (!this.modelSelectedArticles) return [];
    // },

    curArticles() {
      return this.modelSelectedArticles;
    },

    max() {
      if (!this.modelSelectedArticles && !this.modelSelectedDepot) return null;
      else
        return this.oneEntree({
          idArticle: this.modelSelectedArticles.article.idArticle,
          idDepot: this.modelSelectedDepot.idDepot,
        });
    },

    newTotal() {
      let somme = 0;
      if (this.facture != null) {
        this.facture.analyseList.forEach((element) => {
          somme += !element.netPayBeneficiaire
            ? element.price
            : element.netPayBeneficiaire;
        });
      }
      return somme - this.oldRemise - this.newRemise;
    },

    isValidRemise() {
      if (this.newRemise !== 0 && this.newTotal >= 0) return false;
      else return true;
    },

    isValidFacture: {
      get() {
        if (this.ajoutArticle.length === 0) return true;
        else return false;
      },

      set(value) {
        console.log(value);
        return value;
      },
    },

    isValidFactureUp: {
      get() {
        if (this.ajoutArticleUp.length === 0) return true;
        else return false;
      },

      set(value) {
        console.log(value);
        return value;
      },
    },

    isValidPayement: {
      get() {
        if (this.montantPaye === "") return true;
        else return false;
      },

      set(value) {
        console.log(value);
        return value;
      },
    },

    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isVendeur() {
      const isObjectPresent = this._role.find((o) => o.idRole === 4);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isCaissier() {
      const isObjectPresent = this._role.find((o) => o.idRole === 5);
      return isObjectPresent;
      //return this._role <= 4 ? true : false;
    },
    isVendeurCaisse() {
      if (this.isVendeur && this.isCaissier) return true;
      else return false;
    },
    isVendeurCaisseDirecteur() {
      if (this.isVendeur && this.isCaissier && this.isDirector) return true;
      else return false;
    },
  },

  watch: {},

  created() {
    // this.clients();
    this.loading = true;
    if (this.isDirector) {
      this.$store
        .dispatch("facture/FETCH_ALL_FACTURE")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.$store
        .dispatch("facture/FETCH_ALL_FACTURE_USER")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
    this.$store.dispatch("client/FETCH_ALL_CLIENTS");
    this.$store.dispatch("articles/FETCH_ALL_ARTICLES_STOCK");
    this.$store.dispatch("depot/FETCH_ALL_DEPOTS_BOUTIQUE");
    this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
    this.articlesFacture();
    this.typefacture();
    // this.depots();
    if (!this.modelSelectedArticles) return null;
    else return this.oneEntree(this.modelSelectedArticles.article.idArticle);
  },
  mounted() {
    if (this.isDirector !== undefined) {
      this.axiosListDir();
    } else {
      this.axiosList();
    }
  },
  methods: {
    ...mapActions({
      clients: "client/FETCH_ALL_CLIENTS",
      addNewClient: "client/ADD_CLIENT",
      articlesFacture: "articles/FETCH_ALL_ARTICLES_STOCK",
      typefacture: "typefacture/FETCH_ALL_TYPE_FACTURES",
      generateFacture: "facture/ADD_FACTURE",
      updateFacture: "facture/UPDATE_FACTURE",
      payerFacture: "payement/ADD_PAYEMENTS",
      oneEntree: "article/FETCH_ONE_ENTREE_LIGNE",
      deleteFacture: "facture/DELETE_FACTURE",
      oneFacture: "facture/FETCH_ONE_FACTURE",
      depots: "depot/FETCH_ALL_DEPOTS_BOUTIQUE",
      chauffeurs: "chauffeur/FETCH_ALL_CHAUFFEUR",
      addnewchauffeur: "chauffeur/ADD_CHAUFFEUR",
    }),
    async axiosListDir() {
      this.loading = true;

      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/factures/boutique/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.factures = response.data;
            if (this.factures.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearchDir() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchFacDir) {
        await axios
          .get(
            APIENDPOINT +
              "/api/facture/boutique/search/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchFacDir,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.factures = response.data;
              if (this.factures.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosListDir();
      }
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/factures/boutique/user/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.factures = response.data;
            if (this.factures.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchFac) {
        await axios
          .get(
            APIENDPOINT +
              "/api/facture/boutique/user/search/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchFac,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.factures = response.data;
              if (this.factures.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },
    async historiqueList(idFacture) {
      this.loading = true;
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(APIENDPOINT + "/api/paiements_facture/" + idFacture, {
          headers: {
            Authorization: `Bearer ${token.token} `,
          },
        })
        .then((response) => {
          if (response.data) {
            this.paiements = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    newclient() {
      this.client = true;
    },
    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        moment.locale("en");
        convert_date = moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
        // convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        moment.locale("fr");
        convert_date = moment(String(date)).format("YYYY-MM-DD à HH:mm:ss");
        // convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },
    saveclient() {
      this.myloading = true;
      const client = {};
      client.nom = this.nom;
      client.prenom = this.prenom;
      client.boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
      client.telephone = this.telephone;
      this.addNewClient(client).then(() => {
        this.client = false;
        this.nom = "";
        this.prenom = "";
        this.telephone = "";

        this.$store.dispatch("client/FETCH_ALL_CLIENTS");
        // var msg = this.$t("client.success");
        // this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
        //   show: true,
        //   text: msg,
        // });
      });
    },
    isItemOnList() {
      let tab1 = [];
      tab1 = this.ajoutArticle;
      const exist = tab1.find(
        (el) => el.id === this.modelSelectedArticles.article.idArticle
      );
      if (exist != undefined) {
        return exist;
      } else {
        return false;
      }
    },
    showEditFacture(item) {
      // console.log(item);
      this.dialogUpdate = true;
      this.disableBtn = false;
      if (item.facture.commande.client) {
        this.modelSelectedClient = item.facture.commande.client;
      } else {
        this.modelSelectedClient = null;
      }
      this.modelSelectedTypeFacture = item.facture.typeFacture;
      this.itemShow = item;
      this.addTab(item.articles);
    },

    ajout() {
      // console.log(this.getOneEntree);
      if (this.getOneEntree.quantite >= this.quantite) {
        // console.log(this.isitemonlist())
        let article1 = {};
        let factureArt = {};

        let total = this.totalArt;
        let tab = [];
        let tabArt = [];

        let tab1 = [];
        let tabArt1 = [];

        tab1 = this.ajoutArticle;
        tabArt1 = this.articles;

        article1.id = this.modelSelectedArticles.article.idArticle;
        article1.designation = this.modelSelectedArticles.article.designation;
        article1.prix = this.modelSelectedArticles.article.prix;
        article1.tot =
          this.modelSelectedArticles.article.prix * parseInt(this.quantite);
        article1.quantite = this.quantite;

        factureArt.articles = this.modelSelectedArticles.article.idArticle;
        factureArt.nombre = parseInt(this.quantite);
        if (this.isItemOnList() !== false || article1.quantite === "") {
          var msg1 = this.$t(
            "Le produit a ajouter existe deja dans la liste ou n'a pas de quantiter definie"
          );
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg1,
          });
        } else {
          //Liste des articles choisis
          tabArt.push(factureArt);
          this.articles = tabArt1.concat(tabArt);
          // console.log(tabArt);
          tab.push(article1);
          // console.log(tab);
          //Ajout des articles dans le tableau
          this.totalArt = parseInt(total + article1.tot);
          this.ajoutArticle = tab1.concat(tab);
        }
        console.log(this.ajoutArticle);
        // return this.ajoutArticle;
      } else {
        var msg = this.getOneEntree.quantite + this.$t("facture.disponible");
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: true,
          text: msg,
        });
      }

      // console.log(this.ajoutArticle)
      // console.log(this.modelSelectedArticles.article.idArticle in this.ajoutArticle)
    },

    ajoutUp() {
      if (this.getOneEntree.quantite >= this.quantite) {
        // console.log(this.isitemonlist())
        let article1 = {};
        let factureArt = {};

        let total = this.totalArt;
        let tab = [];
        let tabArt = [];

        let tab1 = [];
        let tabArt1 = [];

        tab1 = this.ajoutArticleUp;
        tabArt1 = this.articleUp;

        article1.id = this.modelSelectedArticles.article.idArticle;
        article1.designation = this.modelSelectedArticles.article.designation;
        article1.prix = this.modelSelectedArticles.article.prix;
        article1.tot =
          this.modelSelectedArticles.article.prix * parseInt(this.quantite);
        article1.quantite = this.quantite;
        // console.log(article1.quantite)

        factureArt.articles = this.modelSelectedArticles.article.idArticle;
        factureArt.nombre = parseInt(this.quantite);
        if (this.isItemOnList() !== false || article1.quantite === "") {
          var msg1 = this.$t(
            "Le produit a ajouter existe deja dans la liste ou n'a pas de quantiter definie"
          );
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg1,
          });
        } else {
          //Liste des articles choisis
          tabArt.push(factureArt);
          this.articleUp = tabArt1.concat(tabArt);
          tab.push(article1);
          //Ajout des articles dans le tableau
          this.totalArt = parseInt(total + article1.tot);
          this.ajoutArticleUp = tab1.concat(tab);
        }
        // return this.ajoutArticle;
      } else {
        var msg = this.getOneEntree.quantite + this.$t("facture.disponible");
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: true,
          text: msg,
        });
      }
    },

    addTab(listArticles) {
      for (
        let itemArticle = 0;
        itemArticle < listArticles.length;
        itemArticle++
      ) {
        let article1 = {};
        let factureArt = {};

        let total = this.totalArt;
        let tab = [];
        let tabArt = [];

        // let tab1 = [];
        let tabArt1 = [];

        const article = listArticles[itemArticle];

        // tab1 = this.ajoutArticle;
        tabArt1 = this.articleUp;

        article1.id = article.articles.idArticle;
        article1.designation = article.articles.designation;
        article1.prix = article.articles.prix;
        article1.tot = article.articles.prix * parseInt(article.nombre);
        article1.quantite = article.nombre;

        factureArt.articles = article.articles.idArticle;
        factureArt.nombre = parseInt(article.nombre);

        if (this.isItemOnList() !== false || article1.quantite === "") {
          var msg1 = this.$t(
            "Le produit a ajouter existe deja dans la liste ou n'a pas de quantiter definie"
          );
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg1,
          });
        } else {
          //Liste des articles choisis
          tabArt.push(factureArt);
          this.articleUp = tabArt1.concat(tabArt);

          tab.push(article1);

          //Ajout des articles dans le tableau
          this.totalArt = parseInt(total + article1.tot);
          this.ajoutArticleUp.push(article1); // = tab1.concat(tab);
        }
      }
    },

    removeById(arr, id) {
      const requiredIndex = arr.findIndex((el) => {
        return el.id === String(id);
      });

      return arr.splice(requiredIndex - 1, 1);
    },

    supprimerLigne(item) {
      //const designation = item.designation;
      const tot = this.totalArt;
      const prixTotArt = item.prix * item.quantite;
      const newTot = tot - prixTotArt;

      for (var i = 0; i < this.ajoutArticle.length; i++) {
        if (this.ajoutArticle[i].designation === item.designation) {
          this.ajoutArticle.splice(i, 1);
        }
      }

      for (var j = 0; j < this.articles.length; j++) {
        if (this.articles[j].articles === item.id) {
          this.articles.splice(j, 1);
        }
      }

      this.totalArt = newTot;
      return this.ajoutArticle;
    },

    supprimerLigneUp(item) {
      //const designation = item.designation;
      const tot = this.totalArt;
      const prixTotArt = item.prix * item.quantite;
      const newTot = tot - prixTotArt;

      for (var i = 0; i < this.ajoutArticleUp.length; i++) {
        if (this.ajoutArticleUp[i].designation === item.designation) {
          this.ajoutArticleUp.splice(i, 1);
        }
      }

      for (var j = 0; j < this.articleUp.length; j++) {
        if (this.articleUp[j].articles === item.id) {
          this.articleUp.splice(j, 1);
        }
      }

      this.totalArt = newTot;
      return this.ajoutArticleUp;
    },

    async showFacture(item) {
      await instance
        .get(item.file, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },
    async checkCommande(item) {
      console.log(item.facture.idFacture);
      if (!item.facture.livre) {
        let token = JSON.parse(window.localStorage.getItem("conUser"));
        await axios
          .get(APIENDPOINT + "/api/check/commande/" + item.facture.idFacture, {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          })
          .then((response) => {
            if (response.data) {
              this.payementFacture(item);
            }
          })
          .catch((error) => {
            // console.log(error.response.data);
            var msg = error.response.data;
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
      } else {
        this.switchLivre = false;
        this.payementFacture(item);
      }
    },

    payementFacture(item) {
      this.showItem = item;
      this.editedIndex = this.allFactures.indexOf(item);
      this.dialogView = true;
    },

    closePayement() {
      // console.log("close");
      this.dialogView = false;

      this.myloading = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    historiqueFacture(item) {
      this.historiqueList(item);
      this.editedIndex = this.allFactures.indexOf(item);
      this.historique = true;
    },

    closeHistorique() {
      // console.log("close");
      this.historique = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadFacture(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    close() {
      this.dialog = false;
      this.disableBtn = false;

      this.myloading = false;
      setTimeout(() => {
        //.modelSelectedClient = [];
        // this.modelSelectedArticles = [];
        this.modelSelectedClient = [];
        this.modelSelectedTypeFacture = [];
        this.remise = 0;
        this.ajoutArticle = [];
        this.quantite = "";

        this.nomClient = "";
        this.contactClient = "";
        this.totalArt = 0;
        this.editedItem = null;
        this.editedIndex = -1;
      }, 300);
    },

    getOneFacture(item) {
      this.facture = item;
      this.editedIndex = this.allFactures.indexOf(item);
      this.dialogOneFac = true;
    },

    getTotalOneFacture(facture) {
      let somme = 0;
      facture.analyseList.forEach((element) => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    closeDialogView() {
      this.dialogView = false;
      this.disableBtn = false;

      this.myloading = false;
      setTimeout(() => {
        this.montantPaye = "";
        //this.modelSelectedCaisse = null;
        this.modelSelectedChaufeur = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeUpdateDialogView() {
      this.dialogUpdate = false;
      this.disableBtn = false;

      this.myloading = false;
      setTimeout(() => {
        // this.itemShow = {}
        // this.showItem = {}
        this.modelSelectedClient = null;
        this.modelSelectedArticles = null;
        this.modelSelectedTypeFacture = null;
        this.modelSelectedDepot = null;
        this.remise = 0;
        this.ajoutArticleUp = [];
        this.quantite = "";
        this.articleUp = [];
        this.nomClient = "";
        this.contactClient = "";
        this.totalArt = 0;
        this.editedItem = null;
        this.editedIndex = -1;
      }, 300);
    },

    addInputRemise(payload) {
      if (payload === 1) {
        this.inputRemise = true;
        this.newRemise = 0;
      } else {
        this.inputRemise = false;
        this.newRemise = 0;
      }
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      // this.myloading = true;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("item.msg1") + this.$t("item.msg2") + this.$t("item.msg3");
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteFacture(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("item.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.axiosList();
        this.axiosListDir();
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    update() {
      // console.log(this.isValidPayement);

      this.myloading = true;
      this.isValidPayement = true;
      // console.log(this.isValidPayement);
      this.isValidFactureUp = true;

      this.disableBtn = true;
      if (this.$refs.formFacture.validate()) {
        if (this.newRemise === "") {
          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );
          this.loading = true;
          this.disableBtn = true;
          // this.isValidFactureUp = true;
          const items = {};
          let clientid = 0;
          if (this.modelSelectedClient) {
            clientid = this.modelSelectedClient.idClient;
          }
          items.client = clientid;
          items.id = this.itemShow.idFacture;
          items.boutiques = boutiqueId;
          items.depot = this.modelSelectedDepot.idDepot;
          items.articles = this.articleUp;
          items.remise = 0;
          items.typeFactures = this.modelSelectedTypeFacture.idTypeFacture;
          items.imprimante = this.switch1;
          this.loading = true;
          this.updateFacture(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
              this.axiosList();
              this.axiosListDir();
              this.modelSelectedClient = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.modelSelectedDepot = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articleUp = [];
              this.ajoutArticleUp = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
              this.$store
                .dispatch("facture/FETCH_ALL_FACTURE")
                .then(() => {
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.closeUpdateDialogView();
        } else {
          this.loading = true;
          this.disableBtn = true;
          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );
          const items = {};
          let clientid = 0;
          if (this.modelSelectedClient) {
            clientid = this.modelSelectedClient.idCliAFIent;
          }
          items.client = clientid;
          if (this.modelSelectedClient) {
            items.client = this.modelSelectedClient.idClient;
          } else {
            items.client = 0;
          }
          items.id = this.itemShow.idFacture;
          items.boutiques = boutiqueId;
          items.articles = this.articleUp;
          items.depot = this.modelSelectedDepot.idDepot;
          items.remise = 0;
          items.remise = parseInt(this.newRemise);
          items.typeFactures = this.modelSelectedTypeFacture.idTypeFacture;
          items.imprimante = this.switch1;
          this.loading = true;
          this.updateFacture(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
              this.modelSelectedClient = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.modelSelectedDepot = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articleUp = [];
              this.ajoutArticleUp = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
              this.$store
                .dispatch("facture/FETCH_ALL_FACTURE")
                .then(() => {
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.closeUpdateDialogView();
        }
        this.axiosList();
        this.axiosListDir();
      }
    },
    save() {
      // console.log(this.isValidPayement);

      this.myloading = true;
      this.isValidPayement = true;
      this.isValidFacture = true;
      this.disableBtn = true;
      // console.log(this.isValidPayement);
      if (this.$refs.formFacture.validate()) {
        if (this.newRemise === "") {
          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );

          this.loading = true;
          this.disableBtn = true;
          // this.isValidFacture = true;
          const items = {};
          let clientid = 0;
          if (this.modelSelectedClient) {
            clientid = this.modelSelectedClient.idClient;
          }
          items.client = clientid;
          items.boutiques = boutiqueId;
          items.articles = this.articles;
          items.depot = this.modelSelectedDepot.idDepot;
          console.log("**** Facture Article ****");
          console.log(items.articles);
          items.remise = 0;
          items.imprimante = this.switch1;
          items.typeFactures = this.modelSelectedTypeFacture.idTypeFacture;
          console.log(items);

          this.loading = true;
          this.generateFacture(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
              this.axiosList();
              this.axiosListDir();
              this.modelSelectedClient = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.modelSelectedDepot = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articles = [];
              this.ajoutArticle = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
              // this.$store
              //   .dispatch("facture/FETCH_ALL_FACTURE")
              //   .then(() => {
              //     this.loading = false;
              //   })
              //   .catch(() => {
              //     this.loading = false;
              //   });
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.close();
        } else {
          this.loading = true;
          this.disableBtn = true;

          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );
          const items = {};
          let clientid = 0;
          if (this.modelSelectedClient) {
            clientid = this.modelSelectedClient.idClient;
          }
          items.client = clientid;
          items.boutiques = boutiqueId;
          items.articles = this.articles;
          items.depot = this.modelSelectedDepot.idDepot;
          items.imprimante = this.switch1;
          items.remise = 0;
          items.remise = parseInt(this.newRemise);
          items.typeFactures = this.modelSelectedTypeFacture.idTypeFacture;

          this.loading = true;
          this.generateFacture(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
              this.modelSelectedClient = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.modelSelectedDepot = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articles = [];
              this.ajoutArticle = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
              this.$store
                .dispatch("facture/FETCH_ALL_FACTURE")
                .then(() => {
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.close();
        }
        this.axiosList();
        this.axiosListDir();
      }
    },

    async savePaiement() {
      this.myloading = true;
      if (this.$refs.formPayer.validate()) {
        if (this.switchLivre) {
          if (this.modelSelectedChaufeur == null) {
            var msg = this.$t("Facture.chauffeur");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
            return;
          }
        }

        this.loading = true;
        let items = {};
        //items.caisse = this.modelSelectedCaisse.idCaisse;
        items.facture = this.showItem.idFacture;
        items.montantPaye = parseInt(this.montantPaye);
        items.total = this.showItem.facture.montantFacture;
        items.reste = this.showItem.facture.reste;
        items.livrer = this.switchLivre;
        if (this.modelSelectedChaufeur != null) {
          items.chauffeure = this.modelSelectedChaufeur.id;
        } else {
          items.chauffeure = 0;
          items.livrer = false;
        }

        //console.log(items.facture,items.montantPaye, items.livre);
        // console.log(items);

        await this.payerFacture(items)
          .then(() => {
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            var msg = this.$t("bill.msg");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.axiosList();
            this.axiosListDir();
            this.$store
              .dispatch("facture/FETCH_ALL_FACTURE")
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("bill.msg1");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.closeDialogView();
      }
      this.axiosList();
      this.axiosListDir();
    },
    savechauffeur() {
      this.myloading = true;
      const chauff = {};
      chauff.nom = this.nomChaufeur;
      chauff.telephone = this.contactChauffeur;
      chauff.boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
      this.addnewchauffeur(chauff).then(() => {
        this.newchauff = false;
        this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
      });
    },
  },
};
</script>

<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
.actiondelete {
  font-size: 20px !important;
  color: red !important;
}
.actionedit {
  font-size: 20px !important;
  color: blueviolet !important;
}
.actionpay {
  font-size: 20px !important;
  color: green !important;
}
.actionshow {
  font-size: 20px !important;
  color: blue !important;
}
</style>
